import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, CircularProgress } from '@mui/material';

import { resetPassword, sendOtpEmail } from '../../services/AuthService/AuthServices';

const defaultTheme = createTheme();

export default function ResetPassword() {
  const [loginId, setLoginId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'failed'>('idle');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      "LoginId": loginId,
      "OTP": otp,
      "newPassword": newPassword,
    };

    if (otpSent) {
      // Handle password reset with OTP
      setStatus('loading');
      resetPassword(userData)
        .then(() => {
          setStatus('success');
          setOtpSent(false);
          navigate('/sign-in'); // Navigate to the login page once password is reset successfully
        })
        .catch((error) => {
          setStatus('failed');
          setError('Failed to reset password. Please try again.'); // Set the appropriate error message based on the error response from the API
        });
    } else {
      // Handle sending OTP
      setStatus('loading');
      sendOtp(data)
        .then(() => {
          setStatus('success');
          setOtpSent(true);
        })
        .catch((error) => {
          setStatus('failed');
          setError('Failed to send OTP. Please try again.'); // Set the appropriate error message based on the error response from the API
        });
    }
  };

  const sendOtp = async (data: FormData) => {
    // Assuming you have an API endpoint to send the OTP to the user's email
    // Implement this API call using axios or fetch
    try {
      await sendOtpEmail({ LoginId: loginId }); // Pass the loginId and otp to the sendOtpEmail function
      console.log('OTP Sent Successfully');
    } catch (error: any) {
      console.error('Failed to send OTP:', error.message);
      throw new Error('Failed to send OTP. Please try again.'); // Throwing an error will be caught in the catch block in handleSubmit function
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {otpSent ? 'Reset Password' : 'Forget Password'}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {!otpSent && (
              <>
                <TextField
                  id="LoginId"
                  label="Login Id"
                  name="LoginId"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  value={loginId}
                  onChange={(e) => setLoginId(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  disabled={status === 'loading'}
                >
                  {status === 'loading' ? <CircularProgress /> : 'Send OTP'}
                </Button>
              </>
            )}
            {otpSent && (
              <>
                <TextField
                  id="OTP"
                  label="OTP"
                  name="OTP"
                  margin="normal"
                  required
                  fullWidth
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <TextField
                  id="NewPassword"
                  name="NewPassword"
                  label="New Password"
                  type="password"
                  margin="normal"
                  required
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {status === 'failed' && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  disabled={status === 'loading'}
                >
                  {status === 'loading' ? <CircularProgress /> : 'Reset Password'}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
