import { createSlice } from '@reduxjs/toolkit' ;
import { getUserApps } from '../../../services/AppService/AppServices';


export interface IAppState {
    UserId : string,
    AppId : string,
    AppName : string,
    AppDescription : string,
    AppIconName : string ,
    AppIconPath : string ,
    AppURL : string
}

export interface IAppsState {
    Apps : IAppState[] ,
    status :  "loading" | "idle" | "failed"
    error : string | null | undefined
} 

export const initialState : IAppsState = {
    Apps : [],
    status : "loading",
    error : ""
}

const appSlice = createSlice({
    name : 'Apps',
    initialState ,
    reducers : {
       
    },

  extraReducers: (builder) => {
    builder
      .addCase(getUserApps.pending, (state) => {
        state.status = 'loading';
        state.error = ""
      })
      .addCase(getUserApps.fulfilled, (state, action) => {
        state.Apps = action.payload
        state.status = 'idle';
      })
      .addCase(getUserApps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    ;
  },
})

export const {   } = appSlice.actions ;
export default appSlice.reducer ;



