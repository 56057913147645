import React from "react";

import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import BackgroundHomeCard from "../../assets/images/BackgroundHomeCard.jpg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { KhLoader } from "../../components/KhLoader/KhLoader";

import { getAuthUser } from "../../services/AuthService/AuthServices";
import { getUserApps } from "../../services/AppService/AppServices";
import { getLocalStorageData } from "../../utils/LocalStorage";
import { Navigate } from "react-router-dom";

export default function Dashboard() {
  const dispatch =  useAppDispatch()
  const sessionToken =  getLocalStorageData('accessKey')
  
  const { Apps, status , error} = useAppSelector((state) => state.Apps)
  const userStatus = useAppSelector((state)=> state.User.status)
  const UserId = useAppSelector((state) => state.User.data?.user?.UserId )



  React.useEffect(() => {
    if (sessionToken){
      dispatch(getUserApps(sessionToken as string )) ;
    }
  },[dispatch, sessionToken, UserId]);

  if (!sessionToken) {
  // Redirect to the sign-in page if no session token
    return <Navigate to="/sign-in" />;
  }

  if (status && userStatus === "loading") {
    return <KhLoader />
  }
  
  const handleRedirect = (appURL : string) => {
    window.location.href = appURL;
  };
  
  return (
    <React.Fragment>
      <Grid
        className="HomeCardCss"
        container
        mt={5}
        sx={{
          backgroundImage: `url(${BackgroundHomeCard})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center"}}>
          <Typography sx={{ fontWeight: "bold", fontSize: { xs: 25, sm: 35 } }}>
            HRMS
          </Typography>
        </Grid>
        {Apps && Apps.map((app, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} padding={2}>
              <Card 
              sx={{ cursor: "pointer", height: 250  }}
              onClick={() => handleRedirect(`${app.AppURL}?t=${sessionToken}${UserId}`)}
              >
                <CardContent>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height : "100%",
                      width : "500",
                    }}
                  >
                    <img
                    src={app.AppIconPath}
                    alt={app.AppIconName}
                    style={{ width: "20%", height: "20%" }}
                  />
                  </Grid>
                  <Typography
                    sx={{
                      display: "flex",
                      fontWeight: "bold",
                      fontSize: 20,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {app.AppName}
                  </Typography>
                  <Typography 
                    textOverflow={'ellipsis'}
                    sx={{ fontWeight: 500, fontSize: 16, width: "200" ,  height:"200" }}
                  >
                    {app.AppDescription}
                  </Typography>
                </CardContent>
              </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};




