import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppSelector } from "../../app/hooks";
import { deepOrange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { getLocalStorageData, removeLocalStorageData } from "../../utils/LocalStorage";
import { signOutUser } from "../../services/AuthService/AuthServices";

export default function AvatarIcon() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const sessionToken = getLocalStorageData('accessKey')
  const User = useAppSelector((state) => state.User.data?.user);

  const navigate = useNavigate()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    // Handle logout logic here
    signOutUser()
    .then((data : any) => {
      // Handle successful logout here
      console.log('Logout successful!', data);
    })
    .catch((error) => {
      // Handle errors here or let the component handle them
      console.error('Logout error:', error);
    });
      
    removeLocalStorageData('accessKey')
    navigate('./home')
    handleClose()
  };

  const handleLoginClick = () => {
    navigate('./sign-in')
    handleClose()
  };

  return (
    <React.Fragment>
      <Grid sx={{ display: { xs: "none", md: "flex"} }}>
        <IconButton>
          <SearchIcon sx={{ fontSize: 50, color: "white" }} />
        </IconButton>
        <IconButton>
          <LanguageIcon sx={{ fontSize: 50, color: "white" }} />
        </IconButton>
        <Box paddingTop={1}>

        {sessionToken && sessionToken ? (
          <Tooltip title={User?.FirstName.concat(" ", User?.LastName)}>
            <Avatar
              alt={User?.FirstName.concat(" ", User?.LastName)}
              src={User?.ProfileUrl}
              sx={{
                width: 50,
                height: 50,
                bgcolor: deepOrange[500],
              }}
              onClick={handleMenu}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              />
          </Tooltip>
        ) : (
          <IconButton
          size="large"
          aria-label="sign in"
          onClick={handleMenu}
          >
            <AccountCircle sx={{ fontSize: 50, color: "white" }} />
          </IconButton>
        )}
        </Box>
      </Grid>

      <Grid sx={{ display: { xs: "flex", md: "none", color: "white" } }}>
        <IconButton>
          <SearchIcon sx={{ fontSize: 35, color: "white" }} />
        </IconButton>
        <IconButton>
          <LanguageIcon sx={{ fontSize: 35, color: "white" }} />
        </IconButton>
        <Box paddingTop={1}>
        {sessionToken && sessionToken ? (
          <Tooltip title={User?.FirstName.concat(" ", User?.LastName)}>
            <Avatar
              alt={User?.FirstName.concat(" ", User?.LastName)}
              src={User?.ProfileUrl}
              sx={{
                width: 30,
                height: 30,
                bgcolor: deepOrange[500],
              }}
              onClick={handleMenu}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              />
          </Tooltip>
        ) : (
          <IconButton
          size="large"
          aria-label="sign in"
          onClick={handleMenu}
          >
            <AccountCircle sx={{ fontSize: 35, color: "white" }} />
          </IconButton>
        )}
        </Box>
      </Grid>

      {User?.FirstName ? (
        <>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLoginClick}>Login</MenuItem>
          </Menu>
        </>
      )}
    </React.Fragment>
  );
}
