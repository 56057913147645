import React from "react";
import { AppBar, Grid, Typography } from "@mui/material";
import AvatarIcon from "./AvatarIcon";
import KhTitle from "./KhTitle";
import KhLogo from "./KhLogo";
import HeaderBg from "../../assets/images/HeaderBg.jpg";

export default function HeaderBar(){

  return (
    <React.Fragment>
      <AppBar position="static">
      <Grid container
        sx={{
          backgroundImage: `url(${HeaderBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          p: "5px", // padding to inside content
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: "10px",
            bgcolor: "rgba(255, 255, 255, 0.2)",
            width: "100%",
            m: "auto",
            paddingBlock : 1,
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "row"}}> 
            <KhLogo />
            <KhTitle />
          </Grid>
          <Grid marginRight={2}>
            <AvatarIcon />
          </Grid>
        </Grid>
      </Grid>
      </AppBar>
    </React.Fragment>
  );
};

