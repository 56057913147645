import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export interface IHomeProps {
}

export function Home (props: IHomeProps) {
  return (
    <React.Fragment>
        <Container maxWidth={'lg'}>
        <Typography variant={'h1'} >Home</Typography>
        </Container>
    </React.Fragment>
  );
}
