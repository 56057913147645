import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getAuthUser } from '../../../services/AuthService/AuthServices';
import { removeLocalStorageData } from '../../../utils/LocalStorage';


export interface IUser {
	LoginId: string;
	UserId: number;
	FirstName: string;
	LastName: string;
	Email: string;
	MobileNo: string;
	ProfileUrl: string;
}

export interface IUserData {
  user? : IUser | null ;
  error: string | null ;
  message: string | null ;
}

export interface IAppState {
  data? : IUserData | null
  status: 'loading' | 'idle' | 'failed';
}

const initialState: IAppState = {
  data : null,
  status: "idle",
};

const authUserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAuthUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAuthUser.fulfilled, (state, action : PayloadAction<IUserData>) => {
        state.data = action.payload
        state.status = 'idle'
      })
      .addCase(getAuthUser.rejected, (state, action) => {
        removeLocalStorageData('accessKey');
        state.status = 'failed'
      });
  },
});

export const { } = authUserSlice.actions;
export default authUserSlice.reducer;




