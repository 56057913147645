import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// import AuthReducer from "../pages/auth/reducers/AuthSlice"
import AuthReducer from '../pages/authuser/reducers/AuthSlice';
import UserReducer from '../pages/authuser/reducers/UserSlice';
import AppsReducer from "../pages/dashboard/reducers/appSlice"


export const store = configureStore({
  reducer: {
      Auth : AuthReducer,
      User : UserReducer ,
      Apps : AppsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
