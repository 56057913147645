import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Gif from "../../assets/images/404Error.svg";
import Button from '@mui/material/Button'
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';

export interface IPageNotFoundProps {
}

export function PageNotFound (props: IPageNotFoundProps) {
  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Box mt={10} display={'flex'} flexDirection={'row'} justifyContent={'space-around'}>
          <Box>
            <Typography variant='h3' textAlign={'center'} color={"red"} fontWeight={800}>404</Typography>
              <img src={Gif} width={"100%"}/>
            <Typography variant='h3' textAlign={'center'} fontWeight={400}>Looks like You Lost !</Typography> 
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'} mt={5}>
            <Button variant='outlined' endIcon={<NavigateNextSharpIcon />} size='large' href='/' color='success' >
                Go to Home
            </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
}
