import React from "react";
import { Grid, Typography } from "@mui/material";

const KhTitle = () => {
  return (
    <React.Fragment>
      <Grid
        sx={{ display: { xs: "none", md: "flex" }, flexDirection: "column" }}
      >
        <Typography
          sx={{
            fontFamily: "Arial",
            fontSize: 25,
            fontWeight: 900,
            letterSpacing: ".1rem",
            color: 'rgb(94, 243, 75)'
          }}
        >
          Kh
        </Typography>
        <Typography
          sx={{
            fontFamily: "Arial",
            fontSize: 25,
            fontWeight: 900,
            letterSpacing: ".1rem",
            color: 'rgb(230, 34, 142)'
          }}
        >
          Infinite
        </Typography>
        <Typography
          sx={{
            fontFamily: "Arial",
            fontSize: 25,
            fontWeight: 900,
            letterSpacing: ".1rem",
            color: 'yellow'
          }}
        >
          Possibilities
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Arial",
            fontSize: 15,
            fontWeight: 600,
            color: 'rgb(94, 243, 75)'
          }}
        >
          Kh
        </Typography>
        <Typography
          sx={{
            fontFamily: "Arial",
            fontSize: 15,
            fontWeight: 600,
            color: 'rgb(230, 34, 142)'
          }}
        >
          Infinite
        </Typography>
        <Typography
          sx={{
            fontFamily: "Arial",
            fontSize: 15,
            fontWeight: 600,
            color: 'yellow'
          }}
        >
          Possibilities
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export default KhTitle;
