import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit' ;

import { axiosInstance } from '../ServiceInstance';

export const signInUser = createAsyncThunk('/auth/sign-in', async (data : any) => {
  const response = await axiosInstance.post('/auth/sign-in/', data);
  return response.data
});

export const getAuthUser = createAsyncThunk('/auth/user/get/', async (accessToken : string) => {
  const response = await axiosInstance.get('/auth/user/get/',{
    headers : { Authorization : accessToken }
  });
  return response.data
});

export const signOutUser = async () => {
  const response = await axiosInstance.get('/auth/sign-out/', {},);
  return response.data
};

export const sendOtpEmail = async (data : any) => {
  try {
    const response = await axiosInstance.post('/auth/send/otp/', data);
    return response.data;
  } catch (error) {
    throw new Error('Failed to send OTP. Please try again.'); // Handle the error as per your requirement
  }
};

// Service function to reset password
export const resetPassword = async (data:any): Promise<any> => {
  try {
    const response = await axiosInstance.post('/auth/password/reset/', data);
    return response.data;
  } catch (error) {
    throw new Error('Failed to reset password. Please try again.');
  }
};
