import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit' ;

const Base_URL = process.env.REACT_APP_API_ENDPOINT

// create async thunks
export const getUserApps = createAsyncThunk('/userApps', async ( accessToken : string) => {
    const response = await axios.get(`${Base_URL}/auth/userapps/get/`,{
      headers: { Authorization : `${accessToken}` }
    });
    return response.data;
});

