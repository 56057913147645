import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { signInUser } from '../../../services/AuthService/AuthServices';
import { setLocalStorageData } from '../../../utils/LocalStorage';

export interface IUserData {
  accessKey : string | null ;
  error: string | null ;
  message: string | null ;
}

export interface IAppState {
  data? : IUserData | null
  status: 'loading' | 'idle' | 'failed';
}

const initialState: IAppState = {
  data : null,
  status: "idle",
};

const accessSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      if (state.data) {
        state.data.accessKey = action.payload;
     }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signInUser.fulfilled, (state, action : PayloadAction<IUserData>) => {
        state.data = action.payload
        state.status = 'idle'

        setLocalStorageData('accessKey', action.payload.accessKey)
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.data = {
          accessKey: null,
          message: null,
          error:  'Invalid Credentials.',
        };
        state.status = 'failed'
      });
  },
});

export const { setAccessToken } = accessSlice.actions;
export default accessSlice.reducer;
