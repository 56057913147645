import React from "react";
import { Link, Box } from "@mui/material";
import Logo from "../../assets/logo/KhLogo.png";

const KhLogo = () => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link href="/" sx={{ display: { xs: "block", md: "none" } }}>
          <img
            src={Logo}
            alt="Kh-Logo"
            style={{ width: "60px", height: "60px" }}
          />
        </Link>
        <Link href="/" sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src={Logo}
            alt="Kh-Logo"
            style={{ width: "120px", height: "120px" }}
          />
        </Link>
      </Box>
    </React.Fragment>
  );
};

export default KhLogo;
