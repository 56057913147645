// localStorageUtils.ts

// Function to set data in localStorage
export function setLocalStorageData(key: string, data: any): void {
  const serializedData = JSON.stringify(data);
  localStorage.setItem(key, serializedData);
}

// Function to get data from localStorage
export function getLocalStorageData<T>(key: string): T | string | any | null {
  const serializedData = localStorage.getItem(key);
  if (serializedData) {
    return JSON.parse(serializedData) as T;
  }
  return null;
}

// Function to remove data from localStorage
export function removeLocalStorageData(key: string): void {
  localStorage.removeItem(key);
}
