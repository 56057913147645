import React from 'react';
import './App.css';
// import SignIn from './pages/auth/SignIn';

import { Route, Routes, Navigate, useNavigate  } from 'react-router-dom';
import { Home } from './pages/home/HomePage';
import Dashboard  from './pages/dashboard/Dashboard';
import HeaderBar from './components/KhHeader/HeaderBar';
import { PrivateRoute } from './utils/protectedRoute';
import ResetPassword from './pages/authuser/ResetPassword';
import SignIn from './pages/authuser/SignIn';
import { getLocalStorageData } from './utils/LocalStorage';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setAccessToken } from './pages/authuser/reducers/AuthSlice';
import { PageNotFound } from './pages/404/PageNotFound';
import { setAuthToken } from './services/ServiceInstance';
import { getAuthUser } from './services/AuthService/AuthServices';

function App() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  
  const sessionToken = getLocalStorageData("accessKey");
  const token = useAppSelector((state)=> state.Auth.data?.accessKey)

   React.useEffect(() => {

    if (sessionToken) {
      // Redirect to the dashboard if the session key exists
      setAuthToken(token as string)
      dispatch(setAccessToken(sessionToken as string))
      dispatch(getAuthUser(sessionToken as string))
      navigate('/dashboard')
    }
  }, [dispatch, sessionToken]);

  return (
    <React.Fragment>
      <HeaderBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/*" element={<Navigate to="/sign-in" />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/password/reset' element={<ResetPassword />}/>
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
